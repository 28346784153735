import { FileFromResponse } from '@api/models'
import { ISubmittedApplication, SubmittedApplicationApiClient } from 'api'
import { useSignatureTypes } from 'pages/application/dataProviders/useSignatureTypes'
import { routes } from 'routing'
import { BusyIndicator, DPFormField, DPTextCapitalizeBehavior, FormDebugger, FormikForm, FormSubmit, PageHeader, PageSectionHeader } from '@framework'
import { useApiClient, useSession, useTranslation } from '@hooks'
import { DPInputDate } from 'infrastructure/framework/DPForms/DPInputDate'
import { DPInputDropdown } from 'infrastructure/framework/DPForms/DPInputDropdown'
import { DPInputText } from 'infrastructure/framework/DPForms/DPInputText'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { AgreementCode, ApplicationType } from 'shared'
import { addYears, currentDateString } from 'shared/dateTools'
import { config } from '@config'

interface IForm {
    referenceId: string,
    vehicleRegistrationNumber: string,
    vehicleIdentificationNumber: string,
    bankAccountNumber: string | null,
    bankRegistrationNumber: string | null,
    deliveryDate: string | undefined,
    signatureType: number
}

const isCustomerBankAccountInformationEditable = (code: AgreementCode | undefined) =>
    code !== AgreementCode.SubleasingFL &&
    code !== AgreementCode.SubleasingFLFlex &&
    code !== AgreementCode.DealerLeasing &&
    code !== AgreementCode.DealerLeasingFlex

export function GenerateContractPage() {
    const navigate = useNavigate()
    const { referenceId } = useParams()
    const apiClient = useApiClient(api => new SubmittedApplicationApiClient(api))
    const [application, setApplication] = useState<ISubmittedApplication>()
    const [loadingApplication, setLoadingApplication] = useState(false)
    const [generatingContract,  setGeneratingContract] = useState(false)
    const [initialValues, setInitialValues] = useState<IForm | undefined>(undefined)
    const $t = useTranslation('GenerateContract')
    const session = useSession()
    const dealerId = session.currentDealer?.id
    const isPrivate = application?.type === ApplicationType.Private
    const isLeasing = application?.type === ApplicationType.Leasing
    const [signatureTypes, loadingSignatureTypes] = useSignatureTypes(dealerId, application?.agreementCode);
    const bankAccountInformationEditable = isCustomerBankAccountInformationEditable(application?.agreementCode)
    
    const deliveryDateMin = currentDateString();
    const deliveryDateMax = addYears(deliveryDateMin, 1);

    /*
        Please notice that there is a second argument pass to the .shape() method.
        The seconde argument is not well document (there is an issue to improve it).
        Best explanation what the second argument does can be found here:
        https://github.com/jquense/yup/issues/720#issuecomment-564591045
    */
    const validationSchema = Yup.object().shape({
        bankRegistrationNumber: Yup
            .string()
            .when(
                'bankAccountNumber', {
                    is: (value: any) => !!value,
                    then: Yup.string().required().length(4),
                })
            .label($t('RegNo')),
        bankAccountNumber: Yup
            .string()
            .when(
                'bankRegistrationNumber', {
                    is: (value: any) => !!value,
                    then: Yup.string().required().length(10, $t('bankRegistrationNumberInvalidLengthMessage')),
                })
            .label($t('Account No.')),
    // For some reason validation rule .required() has to be placed after .vin().
    vehicleIdentificationNumber: Yup.string().vin().required().label($t('Chassis No.')),
    vehicleRegistrationNumber: Yup.string().registrationNumber().label($t('Registration No.')),
    deliveryDate: Yup
        .string()
        .maxDate(deliveryDateMax)
        .minDate(deliveryDateMin)
        .required()
        .label($t('Delivery date'))
    }, [['bankRegistrationNumber', 'bankAccountNumber']])

    async function submitValues(values: IForm) {
        const queueContractGeneration =
            isPrivate ||
            (isLeasing && application.isDealerIntegratorCase && config.queueContractGenerationForLeasingAgreementCodes.indexOf(application?.agreementCode) !== -1)
        if(queueContractGeneration){
            setGeneratingContract(true)
            apiClient.queueContractGeneration(session.currentDealer?.id!, values)
                .then(result => {
                    if (result.success) {
                        navigate(routes.applicationSubmittedApplication(referenceId!))
                    }
                })
                .finally(() => setGeneratingContract(false))
        }
        else{
            setGeneratingContract(true)
            apiClient.generateContract(session.currentDealer?.id!, values)
                .then(result => {
                    if(!result.success){
                        return;
                    }

                    return new FileFromResponse(result.response!).download()
                })
                .finally(() => setGeneratingContract(false))
        }
    }

    useEffect(() => {
        if(!dealerId || !referenceId){
            return
        }

        setLoadingApplication(true)
        apiClient.getApplication(dealerId, referenceId)
            .then(response => {
                if(!response){
                    return
                }

                setApplication(response);
            })
            .finally(() => setLoadingApplication(false))
    }, [dealerId, referenceId])

    useEffect(() => {
        if(!application){
            return
        }

        setInitialValues({
            referenceId: application.referenceId,
            bankRegistrationNumber: "",
            bankAccountNumber: "",
            vehicleIdentificationNumber: application.objectSerialNumber,
            vehicleRegistrationNumber: application.objectRegistrationNumber,
            deliveryDate: currentDateString(),
            signatureType: signatureTypes[0]?.id,
        })
    }, [application, signatureTypes.length])

    return (
        <div style={{ width: "700px" }}>
            <PageHeader text={$t('Generate contract')} />
            <BusyIndicator busy={generatingContract || loadingApplication || loadingSignatureTypes}>
                {initialValues &&
                    <FormikForm
                        initialValues={initialValues}
                        onSubmit={submitValues}
                        validationSchema={validationSchema}
                    >
                        <PageSectionHeader>
                            {$t('Car information')}
                        </PageSectionHeader>

                        <DPFormField label={$t('Registration No.')} name="vehicleRegistrationNumber">
                            <DPInputText name="vehicleRegistrationNumber" />
                            <DPTextCapitalizeBehavior name="vehicleRegistrationNumber" />
                        </DPFormField>

                        <DPFormField
                            label={$t('Chassis No.')}
                            name="vehicleIdentificationNumber"
                            required
                        >
                            <DPInputText name="vehicleIdentificationNumber" />
                            <DPTextCapitalizeBehavior name="vehicleIdentificationNumber" />
                        </DPFormField>

                        {bankAccountInformationEditable &&
                            <>
                                <PageSectionHeader>
                                    {$t('Bank information')}
                                </PageSectionHeader>

                                <DPFormField label={$t('RegNo')} name="bankRegistrationNumber">
                                    <DPInputText name="bankRegistrationNumber" />
                                </DPFormField>

                                <DPFormField label={$t('Account No.')} name="bankAccountNumber">
                                    <DPInputText name="bankAccountNumber" />
                                </DPFormField>
                            </>
                        }

                        <PageSectionHeader>
                            {$t('Others')}
                        </PageSectionHeader>

                        <DPFormField label={$t('Delivery date')} name="deliveryDate" required>
                            <DPInputDate
                                name="deliveryDate"
                                minDate={deliveryDateMin}
                                maxDate={deliveryDateMax}
                            />
                        </DPFormField>

                        <DPFormField label={$t('Signing type')} name="signatureType" required>
                            <DPInputDropdown
                                name="signatureType"
                                disabled={signatureTypes.length === 1}
                                options={signatureTypes.map(x => {
                                    return {
                                        key: x.id.toString(),
                                        value: x.id,
                                        text: x.name,
                                    }
                                })}
                            />
                        </DPFormField>

                        <FormSubmit submitName={$t('Generate')} />

                        <FormDebugger />
                    </FormikForm>
                }
            </BusyIndicator>
        </div>
    )
}
