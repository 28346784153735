import { PageSectionHeader } from '@framework'
import { useTranslation } from '@hooks/useTranslation'
import { IProcess, ProcessStageName } from './model/IApplicationStatus'
import styles from './SubmittedApplicationProcess.module.css'

interface IProps {
    process: IProcess
}

export function SubmittedApplicationProcess({ process }: IProps){
    const $t = useTranslation('SubmittedApplicationProcess')
    const stageNameTranslation = (value: ProcessStageName) => {
        switch(value){
            case 'creating_application':
                return $t('creatingApplication')
            case 'collecting_customer_data':
                return $t('collectingCustomerData')
            case 'credit_validation':
                return $t('creditValidation')
            case 'waiting_for_contract_generation':
                return $t('waitingForContractGeneration')
            case 'generating_contract':
                return $t('generatingContract')
            case 'signing_contract':
                return $t('signingContract')
            case 'uploading_contract':
                return $t('uploadingContract')
            case 'delivering_object':
                return $t('deliveringObject')
            default:
                return value
        }
    }
    return (
        <div className={styles['process-component']}>
            <PageSectionHeader>
                { $t('process') }
            </PageSectionHeader>

            <div className={styles['process-stages']}>
            {
                process.stages.map((stage, index) =>
                <div
                    key={index}
                    className={`${styles['process-stage']} ${styles[stage.status]}`}
                >
                    {stageNameTranslation(stage.name)}
                </div>
                )
            }
            </div>
        </div>
    )
}