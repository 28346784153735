import { ISubmittedApplication, SubmittedApplicationApiClient } from 'api/SubmittedApplicationApiClient'
import { IApplicationStatus } from 'pages/application/model/IApplicationStatus'
import { routes } from 'routing'
import { BusyIndicator, DateSpan, DescriptionList, DescriptionListItem, PageHeader, PageSectionHeader } from '@framework'
import { useApiCall, useApiClient, useInterval, useModal, useSession, useTranslation  } from '@hooks'
import { formatNumber } from 'shared/utils'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Dimmer, Grid, Message } from 'semantic-ui-react'
import { SubmittedApplicationDocuments } from './SubmittedApplicationDocuments'
import styles from './SubmittedApplicationPage.module.css'
import { SubmittedApplicationProcess } from './SubmittedApplicationProcess'
import { SubmittedApplicationStatus } from './SubmittedApplicationStatus'
import { SubmittedApplicationButtons } from './SubmittedApplicationButtons'
import { ApplicationType } from 'shared'

export function SubmittedApplicationPage() {
    const session = useSession()
    const { id: referenceId } = useParams()
    const submittedApplicationApiClient = useApiClient(a => new SubmittedApplicationApiClient(a))
    const [application, isLoadingApplication, getApplication] = useApiCall(submittedApplicationApiClient.getApplication)
    const [applicationStatus, , getApplicationStatus] = useApiCall(submittedApplicationApiClient.getStatus)
    const [isGeneratingContract, setIsGeneratingContract] = useState(false)
    const $t = useTranslation('GenerateContract')
    const modal = useModal()

    const navigate = useNavigate()

    useEffect(() => {
        const dealerId = session.currentDealer!.id
        getApplication(dealerId, referenceId!)
        getApplicationStatus(dealerId, referenceId!)
    }, [referenceId])

    useInterval({
        callback: () => getApplicationStatus(session.currentDealer!.id, referenceId!),
        delay: 30 * 1000
    })

    const proceedGenerateContract = () => {
        setIsGeneratingContract(true)
        navigate(routes.generateContract(referenceId!))
    }

    const generateContract = () => {
        if(applicationStatus?.hasContractEverBeenGenerated) {
            modal.confirm({
                message: $t('ContractPossiblySignedWarning'),
                onOk: () => proceedGenerateContract()
            })
            return
        }
        proceedGenerateContract()
    }

    return (
        <article>
            <BusyIndicator busy={isLoadingApplication || isGeneratingContract}>
            {
                !!application &&
                !!applicationStatus &&
                !!session.currentDealer &&
                <ApplicationView
                    dealerId={session.currentDealer!.id}
                    referenceId={referenceId!}
                    application={application}
                    applicationStatus={applicationStatus}
                    buttonsComponent = {
                        <SubmittedApplicationButtons
                            referenceId={referenceId!}
                            application={application}
                            applicationStatus={applicationStatus}
                            generateContract={generateContract}
                        />
                    }
                />}
                </BusyIndicator>
        </article>
    )
}

interface IApplicationView {
    dealerId: number,
    referenceId: string,
    application: ISubmittedApplication,
    applicationStatus: IApplicationStatus,
    buttonsComponent: ReactNode,
}

// Regex checks if a given string is a URL.
// For simplicity it only checks if a string
// starts from http:// or https:// and doesn't
// contains any white characters.
const isUrlRegex = /^http(s):\/\/\S+$/

function ApplicationView({ referenceId, application, applicationStatus, buttonsComponent }: IApplicationView) {
    const $t = useTranslation('SubmittedApplicationPage')
    const integratorMessages =
        applicationStatus.integratorMessages.map((message, index) => {
            // In most cases message contains html ready to present on view,
            // but for status "Awaiting documents" / "Afventer dokumenter",
            // unformatted URL is send. To present it correctly it's necessary
            // to detect such URL and wrap it into <a /> tag (PBI 34257).
            const isUrl = isUrlRegex.test(message)
            if(isUrl){
                return (
                    <a
                        key={index}
                        dangerouslySetInnerHTML={{__html: message}}
                        href={message}
                    />)
            }

            return <div
                key={index}
                dangerouslySetInnerHTML={{__html: message}}
            />
        })

    return (
        <Dimmer.Dimmable>
            <PageHeader text={$t('submittedApplication')}/>
            {
                integratorMessages.length !== 0 &&
                <Message color="yellow" className={styles.message}>
                    {integratorMessages}
                </Message>
            }

            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        {/* Case details section */}
                        <div>
                            <PageSectionHeader>
                                {$t('Case Details')}
                            </PageSectionHeader>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <DescriptionList>
                                            <DescriptionListItem label={$t('Name')}>
                                                {application.customerFullName}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Application Date')}>
                                                <DateSpan value={application.dateReceived} />
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Co Buyer')}>
                                                {application.coBuyerFullName}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Finance Type')}>
                                                {application.agreementType}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Object Type')}>
                                                {application.objectType}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Brand')}>
                                                {application.objectDescription}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Year Model')}>
                                                {application.objectYear}
                                            </DescriptionListItem>
                                        </DescriptionList>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <DescriptionList>
                                            <DescriptionListItem label={$t('Telephone Number')}>
                                                {application.telephoneNumber}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Email')}>
                                                {application.customerEmail}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Registration Number')}>
                                                {application.objectRegistrationNumber}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Case Number')}>
                                                {application.caseNumber}
                                            </DescriptionListItem>

                                            <DescriptionListItem label={$t('Contract Number')}>
                                                {application.contractName}
                                            </DescriptionListItem>

                                <DescriptionListItem label={$t('Status')}>
                                    <SubmittedApplicationStatus status={application.status} />
                                </DescriptionListItem>

                                <DescriptionListItem label={$t('Signature Type')}>
                                    {application.signatureTypeName}
                                </DescriptionListItem>
                            </DescriptionList>
                        </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>

                        {/* Documents section */}
                        <div className="mb-4">
                            <SubmittedApplicationDocuments
                                referenceId={referenceId}
                                canDownloadDocuments={applicationStatus?.canContractDocumentsBeDownloaded}
                                firstIntegratorMessage={applicationStatus?.integratorMessages[0]}
                            />
                        </div>

                        {/* Details section */}
                        <div className="mb-4">
                            <PageSectionHeader>
                                {$t('Details')}
                            </PageSectionHeader>
                            <DescriptionList>
                                { application.type !== ApplicationType.Leasing &&
                                    <DescriptionListItem label={$t('Price')}>
                                        {`kr. ${formatNumber(application.objectPrice)}`}
                                    </DescriptionListItem>
                                }

                                <DescriptionListItem label={$t('Security Amount')}>
                                    {`kr. ${formatNumber(application.downPayment)}`}
                                </DescriptionListItem>

                                { application.type !== ApplicationType.Leasing &&
                                    <DescriptionListItem label={$t('Needs Amount')}>
                                        {`kr. ${formatNumber(application.financedAmount)}`}
                                    </DescriptionListItem>
                                }

                                <DescriptionListItem label={$t('monthlyPayment')}>
                                    {`kr. ${formatNumber(application.monthlyPayment)}`}
                                </DescriptionListItem>

                                { application.type !== ApplicationType.Leasing &&
                                    <DescriptionListItem label={$t('interestRate')}>
                                        {`${formatNumber(application.interestRate)} %`}
                                    </DescriptionListItem>
                                }

                                <DescriptionListItem label={$t('No Of Terms')}>
                                    {application.numberOfTerms}
                                </DescriptionListItem>
                            </DescriptionList>
                        </div>
                        
                        {/* Buttons section */}
                        { buttonsComponent }
                    </Grid.Column>

                    <Grid.Column width={4}>
                        { application.type === ApplicationType.Private &&
                            <SubmittedApplicationProcess process={applicationStatus.applicationProcess} />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Dimmer.Dimmable>
    )
}

